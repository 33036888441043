.payment-container {
    max-width: 400px;
    margin: auto;
    padding: 50px;
    background: #feffff;
    border-radius: 10px;
    text-align: center;
    margin-top: 20px;

    /* Sombra más intensa y con más profundidad */
    box-shadow: 10px 10px 20px 10px rgba(0, 0, 0, 0.3), 
                -5px -5px 15px rgba(255, 255, 255, 0.5);
}


.payment-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.payment-message-text-error{
    font-size: 14px;
    color:  #333;
    font-weight: bold;
    margin-bottom: 20px;
}

.folio{
    font-size: 16px;
    color: rgb(70, 4, 4);
    font-weight: bold;
    margin-bottom: 20px;
}

.payment-message-text-success{
    font-size: 16px;
    color: rgb(4, 70, 4);
    font-weight: bold;
    margin-bottom: 20px;
}

.payment-linum img {
    width: 300px;
    margin-bottom: 10px;
}

.payment-logo img {
    width: 200px;
    margin-bottom: 20px;
}

.payment-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

label {
    font-size: 14px;
    color: #555;
    display: block;
    margin-bottom: 5px;
}

.form-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.form-row {
    display: flex;
    justify-content: space-between;
}

.payment-button {
    background-color: #115e59;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
}

.payment-button:hover {
    background-color: #0f766e;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto; /* Centra horizontalmente dentro del contenedor */

  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Para móviles, apilar los inputs en lugar de usar flex */
@media (max-width: 480px) {
    .form-row {
        flex-direction: column;
    }

    .form-row .form-group {
        width: 100%; /* En móviles, los inputs ocupan todo el ancho */
    }
}