.navbar {
    text-align: center;
    background-color: #ffffff;

    .Link {
        text-decoration: none;
        color: inherit;
    }

    .WrapperTop {

        justify-content: center;
        display: flex;
        background-color: var(--btn-color);

        .ContenedorTop {
            justify-content: center;
            width: 95%;
            max-width: 1620px;
            margin: 0px 5px 0px 5px;

            .top {
                font: var(--Font-top);
                display: flex;
                gap: 90px;
                justify-content: center;
                color: #FFFF;
                align-items: center;
                transition: 1s;

                .itemTop {

                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    text-align: justify;

                    .left {
                        display: flex;
                    }

                    .center {
                        text-align: center;
                    }

                    .right {

                        display: flex;
                        justify-content: right;

                        .item {
                            justify-content: space-between;
                        }
                    }
                }
            }
        }
    }

    .WrapperMid {
        margin: 10px 0px 0px 0px;
        display: flex;
        justify-content: center;

        .ContenedorMid {
            justify-content: center;
            width: 95%;
            margin: 0px 5px 0px 5px;

            .midMobile {
                display: none;
            }

            .mid {
                display: flex;
                gap: 90px;
                align-items: center;

                .itemMid {
                    flex: 1;
                    flex-direction: column;
                    //text-align: justify;
                    text-align: center;
                    font-size: 12px;
                    color: gray;

                    .search {
                        display: flex;

                        .textfield {
                            text-decoration: none;
                            border: 1px solid #8a9294;
                        }

                        .btnBuscar {
                            border: none;
                            color: var(--btn-color);
                            ;
                            text-decoration: none;
                            cursor: pointer;
                            background-color: #dde0e0;
                        }
                    }

                    .logo {

                        img {
                            height: 89px;
                            transition: 1s;
                        }

                    }

                    .carrito {
                        .item {
                            .link {
                                text-decoration: none;
                                color: var(--btn-color);
                            }
                        }

                        display:flex;
                        justify-content: right;
                        color: var(--btn-color);

                        .carticon {
                            position: relative;
                            margin-right: 5px;
                            cursor: pointer;

                            span {
                                font-size: 12px;
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                background-color: rgb(221, 4, 4);
                                color: white;
                                position: absolute;
                                right: -10px;
                                top: -10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }

                }
            }
        }
    }

    .WrapperBottom {

        display: flex;
        margin: 20px 0px 0px 0px;
        justify-content: center;

        .ContenedorBottom {
            justify-content: center;
            width: 95%;
            max-width: 1620px;

            .bottom {
                display: flex;
                gap: 95px;
                justify-content: center;
                margin-bottom: 10px;


                .menu {
                    display: flex;
                    padding-top: 10px;
                    justify-content: center;


                    .item {
                        display: flex;
                        padding-inline: 10px;
                    }

                    .Link {
                        position: relative;
                        text-decoration: none;
                        text-align: left;
                        font: var(--Font-Menu);
                        letter-spacing: 0.09px;
                        color: #232323;
                        opacity: 1;


                    }

                    .Link:hover {
                        color: #4198b4;
                        transition: transform .4s ease-in-out;
                    }

                    .Link::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        border-radius: 4px;
                        background-color: #4198b4;
                        bottom: 0;
                        left: 0;
                        transform-origin: right;
                        transform: scaleX(0);
                        transition: transform .4s ease-in-out;
                    }


                    .Link:hover::before {
                        transform-origin: left;
                        transform: scaleX(1);
                        color: #91c2d3;
                    }
                }

            }
        }



    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Fondo semi-transparente */
        z-index: 999;
        /* Asegura que esté en la parte superior */
        display: none;
        /* Inicialmente oculto */
    }





    @media only screen and (min-width: 900px) and (max-width: 1100px) {
        .WrapperTop {
            .ContenedorTop {
                .top {
                    gap: 10px;
                    display: flex;

                    .itemTop {
                        flex: auto;

                        .right {
                            display: none;
                        }
                    }
                }
            }
        }

        .WrapperMid {

            .ContenedorMid {

                .midMobile {
                    display: none;
                }

                .mid {
                    display: flex;
                    gap: 90px;
                    align-items: center;

                    .itemMid {
                        .logo {

                            img {
                                height: 60px;
                                transition: 1s;
                            }

                        }


                    }
                }
            }
        }

    }

    @media only screen and (min-width: 345px) and (max-width: 900px) {
        .WrapperTop {
            .ContenedorTop {
                .top {
                    gap: 10px;
                    display: flex;

                    .itemTop {
                        flex: auto;

                        .right {
                            display: none;
                        }
                    }
                }
            }
        }

        .WrapperMid {
            .ContenedorMid {

                .mid {
                    display: none;
                }

                .midMobile {
                    display: inline;
                    flex-direction: row;
                    width: 100%;

                    .midTop {
                        display: flex;
                        justify-content: space-between;

                        .itemMidMenu {}

                        .itemMidLogo {
                            .logo {
                                img {
                                    height: 40px;
                                    transition: 1s;
                                }
                            }
                        }

                        .itemMidCarrito {
                            .carrito {
                                .item {
                                    .link {
                                        text-decoration: none;
                                        color: var(--btn-color);
                                    }
                                }

                                display:flex;
                                justify-content: right;
                                color: var(--btn-color);

                                .carticon {
                                    position: relative;
                                    margin-right: 5px;

                                    span {
                                        font-size: 12px;
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                        background-color: rgb(221, 4, 4);
                                        color: white;
                                        position: absolute;
                                        right: -10px;
                                        top: -10px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }
                        }

                    }

                    .itemMidSearch {
                        .search {
                            display: flex;

                            .textfield {
                                width: 100%;
                            }
                        }
                    }

                    .itemMidMenu {
                        z-index: 99;
                    }
                }
            }
        }

        .WrapperBottom {
            display: none;
        }

    }

    @media only screen and (max-width: 345px) {
        .WrapperTop {
            .ContenedorTop {
                .top {
                    gap: 10px;
                    display: flex;

                    .itemTop {
                        flex: auto;

                        .right {
                            display: none;
                        }
                    }
                }
            }
        }

        .WrapperMid {
            .ContenedorMid {

                .mid {
                    display: none;
                }

                .midMobile {
                    display: inline;
                    flex-direction: row;
                    width: 100%;

                    .midTop {
                        display: flex;
                        justify-content: space-between;

                        .itemMidMenu {}

                        .itemMidLogo {
                            .logo {
                                img {
                                    height: 25px;
                                    transition: 1s;
                                }
                            }
                        }

                        .itemMidCarrito {
                            .carrito {
                                .item {
                                    .link {
                                        text-decoration: none;
                                        color: var(--btn-color);
                                    }
                                }

                                display:flex;
                                justify-content: right;
                                color: var(--btn-color);

                                .carticon {
                                    position: relative;
                                    margin-right: 5px;

                                    span {
                                        font-size: 12px;
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                        background-color: rgb(221, 4, 4);
                                        color: white;
                                        position: absolute;
                                        right: -10px;
                                        top: -10px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }
                        }

                    }

                    .itemMidSearch {
                        .search {
                            display: flex;

                            .textfield {
                                width: 100%;
                            }
                        }
                    }

                    .itemMidMenu {
                        z-index: 99;
                    }
                }
            }
        }

        .WrapperBottom {
            display: none;
        }
    }
}