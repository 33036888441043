.wrapperCatalogo{
    justify-content: center;
    opacity: 1;
    display: flex;
    margin-bottom:150px;
    margin-top:60px;

    .Link{
        text-decoration:none;
        color:inherit;
    }

    html {
     scroll-behavior: smooth;
    }
    .contentCatalogo{


        justify-content: center;
        width: 95%; 
        max-width: 1620px;

        .rutaCatalogo{
            font: normal normal medium 18px/21px Raleway;
            letter-spacing: 0px;
            color: #CDCFD1;
            opacity: 1;
            display: flex;
            
            img{
                width: 54px;
                height: 47px;
            }
            .t2
            {
                font: normal normal medium 18px/21px Raleway;
                letter-spacing: 0px;
                color: #4198B4;
                opacity: 1;
            }
        }

        .FiltrosTop
        {
            display: grid;
            grid-template-columns: 1fr 2fr;
            .title
            {
                h1{
                    margin:0;
                    position:relative;
                    display: inline-block;
                        
                }
                h1::after{
                    content: '';
                    position: absolute;
                    width: 40%;
                    height: 3px;
                    background-color: var(--btn-color);
                    top:20px;
                    left: 170px;
                } 
            }
            .Filtro
            {
                .FiltrosItems{
                    display: flex;

                    .active{
                        background-color: var(--btn-color);
                    }
                    
                    .Link{
                        background-color: var(--btn-color);
                        text-decoration:none;
                        color:inherit;

                        .ContentItem{
                            background-color: #F2F2F2;
                            width: 211px;
                            height: 52px; 
                            
                            .item{
                                width: 211px;
                                height: 52px; 
                                align-items: center;
                                justify-content: center;
                                display: flex;
                                color:#A7A7A7;  
                            }
                        }
                    }

                    .selected{
                        background-color: var(--btn-color);
                        text-decoration:none;
                        color:inherit;

                        .ContentItem{
                            background-color: var(--btn-color);
                            width: 211px;
                            height: 52px; 
                            
                            .item{
                                width: 211px;
                                height: 52px; 
                                align-items: center;
                                justify-content: center;
                                display: flex;
                                color:#A7A7A7;  
                            }
                        }
                    }

                    .ContentItem:hover{
                        background-color: var(--btn-color);
                    }
                }
            }
        }

        .ProductosContent{
            display: flex;
            margin-top: 50px;
            
            .ProductoLeft{
                width: 25%;
                display: grid;
                height: 100%;
                
                .Filtros{
                    margin-bottom:30px;
                    h1{
                        margin:0;
                        font: normal normal normal 20px/24px Helvetica;
                        color: #393D46;
                        margin-bottom:10px;
                    }
                    .inputItem
                    {
                        font: normal normal normal 18px/22px Helvetica;
                        color: #A7A7A7;
                        line-height: 30px;

                        &:checked{
                            color:aqua;
                        }
                    }
                }
            }
            .ProductoRight{
                width: 100%;
                
                .ProductoOrden{
                    display: flex;
                    justify-content: space-between;
                    color: #A7A7A7;
                    font: normal normal normal 18px/22px Helvetica;

                    .CantProductos{
                    }
                    .OrdenProductos{
                    }
                }
                .CatalogoDeProductos{

                    .Productos{
                        gap:20px;
                        row-gap: 40px;
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        margin-top:40px;

                        .itemProducto{
                            //margin-top:80px;
                            background: #ffffff 0% 0% no-repeat padding-box;
                            box-shadow: 0px 3px 6px #00000029;
                            padding: 2px;
                            height: 100%;
                            margin-bottom: 5px;

                            .images{
                                width: 100%;
                                height: 300px;
                                overflow: hidden;
                                position: relative;
                                margin-bottom: 10px;

                                &:hover{
                                    .SecondImg{
                                        z-index: 2;
                                    }
                                }

                                img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    position: absolute;
                                }

                                .mainImg{
                                    z-index: 1;
                                }

                                .detallesimg
                                {
                                    background: #f2f2f2;
                                    position:absolute;
                                    letter-spacing: 0.9px;
                                    color: #232323;
                                    z-index: 3;

                                    h1{
                                        font: normal normal 600 13px/20px Raleway;
                                        padding-left: 20px;
                                        padding-right: 20px;
                                    }
                                    .Link{
                                        text-decoration: none;
                                        color: #232323;
                                    }
                                }

                            }

                            .titulo{
                                font: normal normal normal 23px/28px Helvetica;
                                color: #232323;
                                margin-bottom:10px;
                                gap:10px;
                                margin-left: 5px;
                                display: flex;
                                justify-content: space-between;
                                color: #294B58;
                                .existencias{
                                    font-size: 15px;
                                    color: #ff1919;
                                }
                                .Baja{
                                    color: #faa811;
                                }
                                .img {
                                    color: #294B58;
                                }
                            }

                            .preciosContent{
                                display: flex;
                                align-items: center;
                                gap:10px;
                                margin-left: 5px;
                                .precioDescuento{
                                    .price{
                                    
                                    }

                                    .priceT{
                                        text-decoration:line-through;
                                        color:#A7A7A7;
                                        font: normal normal bold 18px/22px Helvetica;
                                    }
                                    p{
                                        font: normal normal bold 24px/29px Helvetica;
                                        color: #4198B4;
                                        margin:0;
                                    }
                                }
                                .precioLista{
                                    font: normal normal bold 24px/29px Helvetica;
                                        color: #ff1919;
                                        margin:0;
                                }
                            }
                        }
                    }

                    .Productos2{
                        display: flex;
                        gap:20px;

                        .itemProducto{
                            flex: 1;
                            margin-top:80px;
                            gap:10px;
                            
                            .img{
                                width: 100%;
                                height: 300px;
                                background-color: #393D46;
                            }
                            .titulo{}
                            .precioLista{}
                            .precioDescuento{}
                        }
                    }
                }

            }
        }
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){

        .contentCatalogo{
            width: 100%; 
            display: inline;

            .FiltrosTop
            {
                display: inline;
                .title
                {
                    margin-bottom: 20px;
                   
                    h1::after{
                       display: none;
                    } 
                }
            }

            .ProductosContent{
                padding: 5px;
                margin-top:5px;

                .ProductoLeft{
                    display: none;
                }
            
                .ProductoRight{
                    width: 100%;
                    
                    .ProductoOrden{
                        display: flex;
                        justify-content: space-between;
                        color: #A7A7A7;
                        font: normal normal normal 18px/22px Helvetica;

                        .CantProductos{
                            display: none;
                        }
                        .OrdenProductos{
                            display: none;
                        }
                    }
                    .CatalogoDeProductos{

                        .Productos{
                            gap:20px;
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);

                            .itemProducto{
                                .images{
                                    height: 200px;
                                }
                                .titulo{
                                    //display: inline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 345px) and (max-width: 900px){

        .contentCatalogo{
            width: 100%; 
            display: inline;

            .FiltrosTop
            {
                display: inline;
                width: 100%;

                .title
                {
                    margin-bottom: 20px;
                   
                    h1::after{
                       display: none;
                    } 
                }
                .Filtro
                {
                    display: flex;
                    width: 100%;
                   .FiltrosItems{
                        display: inline;
                        width: 100%;

                        .Link{
                            background-color: var(--btn-color);
                            text-decoration:none;
                            color:inherit;
    
                            .ContentItem{
                                background-color: #F2F2F2;
                                width: 100%;
                                height: 52px; 
                                
                                .item{
                                    width: 100%;
                                    height: 52px; 
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                    color:#A7A7A7;  
                                }
                            }
                        }

                        .selected{
                            background-color: var(--btn-color);
                            text-decoration:none;
                            color:inherit;
    
                            .ContentItem{
                                background-color: var(--btn-color);
                                width: 100%;
                                height: 52px; 
                                
                                .item{
                                    width: 100%;
                                    height: 52px; 
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                    color:#A7A7A7;  
                                }
                            }
                        }
                    }
                }
                
            }

            .ProductosContent{
                padding: 5px;
                margin-top:5px;

                .ProductoLeft{
                    display: none;
                }
            
                .ProductoRight{
                    width: 100%;
                    
                    .ProductoOrden{
                        display: flex;
                        justify-content: space-between;
                        color: #A7A7A7;
                        font: normal normal normal 18px/22px Helvetica;

                        .CantProductos{
                            display: none;
                        }
                        .OrdenProductos{
                            display: none;
                        }
                    }
                    .CatalogoDeProductos{

                        .Productos{
                            gap:20px;
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);

                            .itemProducto{
                                .images{
                                    height: 200px;
                                }
                                .titulo{
                                    font: normal normal normal 19px/23px Helvetica;
                                    //display: inline;
                                }

                                .preciosContent{
                                    .precioDescuento{
                                        .priceT{
                                            font: normal normal bold 16px/22px Helvetica;
                                        }
                                        p{
                                            font: normal normal bold 22px/29px Helvetica;
                                        }
                                    }
                                    .precioLista{
                                        font: normal normal bold 22px/29px Helvetica;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 345px) {

        .contentCatalogo{
            width: 100%; 
            display: inline;

            .FiltrosTop
            {
                display: inline;
                width: 100%;

                .title
                {
                    margin-bottom: 20px;
                   
                    h1::after{
                       display: none;
                    } 
                }
                .Filtro
                {
                    display: flex;
                    width: 100%;
                   .FiltrosItems{
                        display: inline;
                        width: 100%;

                        .Link{
                            background-color: var(--btn-color);
                            text-decoration:none;
                            color:inherit;
    
                            .ContentItem{
                                background-color: #F2F2F2;
                                width: 100%;
                                height: 52px; 
                                
                                .item{
                                    width: 100%;
                                    height: 52px; 
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                    color:#A7A7A7;  
                                }
                            }
                        }

                        .selected{
                            background-color: var(--btn-color);
                            text-decoration:none;
                            color:inherit;
    
                            .ContentItem{
                                background-color: var(--btn-color);
                                width: 100%;
                                height: 52px; 
                                
                                .item{
                                    width: 100%;
                                    height: 52px; 
                                    align-items: center;
                                    justify-content: center;
                                    display: flex;
                                    color:#A7A7A7;  
                                }
                            }
                        }
                    }
                }
                
            }

            .ProductosContent{
                padding: 5px;
                margin-top:5px;

                .ProductoLeft{
                    display: none;
                }
            
                .ProductoRight{
                    width: 100%;
                    
                    .ProductoOrden{
                        display: flex;
                        justify-content: space-between;
                        color: #A7A7A7;
                        font: normal normal normal 18px/22px Helvetica;

                        .CantProductos{
                            display: none;
                        }
                        .OrdenProductos{
                            display: none;
                        }
                    }
                    .CatalogoDeProductos{

                        .Productos{
                            gap:20px;
                            display: grid;
                            grid-template-columns: repeat(1, 1fr);

                            .itemProducto{
                                .images{
                                    height: 200px;
                                }
                                .titulo{
                                    font: normal normal normal 19px/23px Helvetica;
                                    //display: inline;
                                }

                                .preciosContent{
                                    .precioDescuento{
                                        .priceT{
                                            font: normal normal bold 15px/22px Helvetica;
                                        }
                                        p{
                                            font: normal normal bold 21px/29px Helvetica;
                                        }
                                    }
                                    .precioLista{
                                        font: normal normal bold 21px/29px Helvetica;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        
    }

}