.wrapperDetalleCatalogo{
    justify-content: center;
    opacity: 1;
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:150px;
    margin-top:60px;

  .Link{
      text-decoration:none;
      color:inherit;
  }

    .contentCatalogo{
    
        justify-content: center;
        width: 100%; 
        max-width: 1620px;
        margin-bottom:50px;
        
    


      .rutaCatalogo{
          font: normal normal medium 18px/21px Raleway;
          letter-spacing: 0px;
          color: #CDCFD1;
          opacity: 1;
          display: flex;
          
          img{
              width: 54px;
              height: 47px;
          }
          .t2
          {
              font: normal normal medium 18px/21px Raleway;
              letter-spacing: 0px;
              color: #4198B4;
              opacity: 1;
          }
      }

        .tituloMovil{
        display: none;
        }

        .topCatalogo{
            display: flex;
            margin-bottom:50px;

          .textoCatalogo{
              width:50%;
              display: grid;

              .contact{
                  display:grid;
                  align-items: center;
              }

            .Link{
            text-decoration:none;
            color:inherit;
            width: 250px;
            height: 64px;

            .btnAgregar{
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 2px solid #294B58;
                opacity: 1;
                width: 245px;
                height: 64px;
                justify-content: center;
                align-items: center;
                display: flex;
                cursor: pointer;
                color: #1D4C5A;

                
                .contentAgregar{
                    font: normal normal bold 20px/24px Helvetica;
                    letter-spacing: 0px;
                    text-transform: uppercase;
                    opacity: 1;
                    display: flex;
                    gap:20px;

                    .img{
                        width: 24px;
                        height: 27px;
                    }
                }
                
            }
            .btnAgregar:hover{
                background-color: #f1f1f1;
            }
            .inactivo {
                filter:opacity(50%) /* Ejemplo de cambio de color, puedes ajustarlo según tus necesidades */
            }

            }

              .titulo{
                  h1{
                      font: normal normal bold 52px/62px Helvetica;
                      letter-spacing: 0px;
                      color: #050507;
                      opacity: 1;
                      margin: 0;
                  }
              }
              .existencias{
                color:#B44141;
                font: normal normal 500 25px/48px Helvetica;
                }
                .Baja{
                    color: #faa811;
                }
              .rate{
                  .icons{
                      color: #1D4C5A;
                  }
              }
              .preciosContent{

                  display: flex;
                  align-items: center;
                  gap:40px;
                  margin-bottom:40px;

                  .precio{
                      font: normal normal normal 36px/43px Helvetica;
                      letter-spacing: 0px;

                      .price{
                          font: normal normal bold 40px/48px Helvetica;
                          color: #4198B4;
                      }
                  }
                  .precioDescuento{
                          display: flex;
                          margin:0;
                          align-items: center;
                          gap:5px;

                          .priceD{
                              font: normal normal bold 40px/48px Helvetica;
                              color: #B44141;
                          }
                          .priceT{
                              text-decoration:line-through;
                              color:#A7A7A7;
                              font: normal normal bold 25px/30px Helvetica;
                          }
                  }
              }
              .cantidad{
                  font: normal normal medium 18px/21px Raleway;
                  letter-spacing: 0px;
                  color: #A7A7A7;
                  opacity: 1;
                  
                  .cantidadContent{
                      display: flex;
                      align-items: center;
                      gap:20px;

                      .btn{
                          border: none;
                          color: white;
                          text-decoration: none;
                          cursor: pointer; 
                          
                      }

                      .LabelCantidad{
                          font: normal normal medium 18px/21px Raleway;
                          letter-spacing: 0px;
                          color: #A7A7A7;
                          opacity: 1;
                      }

                      .contentBtn{
                          width: 48px;
                          height: 50px;
                          display: flex;
                          background-color: var(--btn-color);
                          justify-content: center;
                          align-items: center;
                          
                      }
                      .contentBtnDes{
                          width: 48px;
                          height: 50px;
                          display: flex;
                          background-color: #dcdcdc;
                          justify-content: center;
                          align-items: center;
                          
                      }
                      h1{
                          font: normal normal medium 18px/21px Raleway;
                      }
                      .contador{
                          text-align: left;
                          font: normal normal bold 24px/29px Helvetica;
                          letter-spacing: 0px;
                          color: #4198B4;
                          opacity: 1;
                      }
                  }
              }
              .agregado{
                height: 20px;
                color: #027091;
                .txt{
                    background-color: #b9ffdd;
                    width: 250px;
                    padding:5px;
                }
                .err{
                    background-color: #fff4b9;
                }
              }

          }
          .slideCatalogo{
              width:50%;  
                      
              .thumbnail-container {
                  display: flex;
                  margin-bottom: 10px;
                  width: 500px;
              }
              
              .thumbnail-container img {
                  width: 100px;
                  height: 100px;
                  object-fit: cover;
                  margin-right: 10px;
                  cursor: pointer;
              }
              
              .slider {
                  margin-top: 10px;
                  margin-bottom: 5px;
                  height: 500px;
                  img{
                      height: 467px;
                  }
              }
          }
      }

        .bottomCatalogo {
            display: grid;
            grid-template-columns: auto auto;
            gap: 3rem;
      
            &__col {
              .descripcionCatalogo {
                text-align: left;
                font: normal normal normal 18px/26px Raleway;
                letter-spacing: 0px;
                color: #2d2d2d;
                opacity: 1;
                margin-top: 3rem;
              }
              h1 {
                position: relative;
                font: normal normal bold 30px/36px Raleway;
                letter-spacing: 0px;
                color: #232323;
                opacity: 1;
              }
      
              h1::after {
                content: "";
                position: absolute;
                width: 15%;
                height: 3px;
                background-color: var(--btn-color);
                top: 15px;
                left: 220px;
              }
      
              ul {
                display: grid;
                grid-template-columns: repeat(2, minmax(150px, 1fr));
                grid-row-gap: 20px;
              }
      
              li {
                list-style: none;
                display: grid;
                align-items: center;
                grid-template-columns: 25px auto;
                gap: 10px;
              }
      
              .list_item {
                background-color: #4198b4;
                color: white;
                height: 2 rem;
                width: 2 rem;
                display: flex;
                align-items: center;
                justify-content: center;
                align-self: center;
              }
            }
          }
      
          .slide {
            opacity: 0;
            transition-duration: 1s ease;
          }
      
          .slide.active {
            opacity: 1;
            transition-duration: 1s;
            transform: scale(1.03);
          }
    }
    .crosselling {

        h1 {
        position: relative;
        font: normal normal bold 30px/36px Raleway;
        letter-spacing: 0px;
        color: #232323;
        opacity: 1;
        }
    
        h1::after {
            content: "";
            position: absolute;
            width: 8%;
            height: 3px;
            background-color: var(--btn-color);
            top: 15px;
            left: 290px;
        }
    
        img {
        width: 15rem;
        height: 15rem;
        }
    
        img:hover, .prod_headers:hover {
            filter: brightness(80%);
        }
    
        .prod_headers {
        transform: translate(20px, 200px);
        width: 10rem;
        position: relative;
        z-index: 1;
        }
    
        a {
            text-decoration: none;
        }
    
        h3 {
        color: white;
        font: normal normal normal 19px/24px Raleway;
        color: #ffffff;
        text-shadow: 1px 1px 0 rgb(128, 128, 128);
        margin: 0 0;
        padding: 5px 5px;
        }
    
        .h3_transparent {
        visibility: hidden;
        }
    
        .h3_linethrough {
        text-decoration: line-through;
        }
    
        button::before {
        color: var(--btn-color);
        }
    }

    @media only screen and (max-width: 1100px){


       
    
    }

    @media only screen and (min-width: 900px) and (max-width: 1100px){
        .contentCatalogo{
            width: 100%; 
            

            .rutaCatalogo{
              font: normal normal medium 15px/18px Raleway;
            }

            .tituloMovil{
                display: flex;
                    h1{
                        font: normal normal bold 30px/62px Helvetica;
                        letter-spacing: 0px;
                        color: #050507;
                        opacity: 1;
                        margin: 0;
                    }
                } 

            .topCatalogo{
                display: flex;
                flex-direction: column;

                .textoCatalogo{
                    width:100%;
                    order: 2;
                    gap:20px;
                    justify-content: center;

                    .titulo{
                    display: none;
                    }
                    .rate{
                        .icons{
                            color: #1D4C5A;
                        }
                    }
                    .preciosContent{
                        margin: 0;
                    }
                    .cantidad{
                    }
                    .btnAgregar{}
                }

                .slideCatalogo{
                    order:1;
                    width:100%;  
                            
                    .thumbnail-container {

                        width: 350px;
                    }
                    
                    .slider {
                        height: 350px;
                        img{
                            height: 340px;
                        }
                    }
                }
            }

            .bottomCatalogo {
                display: inline;

                
                
                .bottomCatalogo__col{
                    display: grid;
                    gap:20px;
                    margin-bottom: 20px;
                    h1 {
                        font-size: 25px;
                    }
                    h1::after {
                        width: 10%;
                        top: 18px;
                      }
                    .descripcionCatalogo{
                        margin:0px;
                    }
                    
                }
            }
        }
        .crosselling {

            h1 {
            font: normal normal bold 25px Raleway;
            }
        
            h1::after {
            content: "";
            position: absolute;
            width: 10%;
            height: 3px;
            background-color: var(--btn-color);
            top: 15px;
            left: 250px;
            }
        }
    }
    @media only screen and (min-width: 380px) and (max-width: 900px) {
       
        .contentCatalogo{
            width: 100%; 
            

            .rutaCatalogo{
              font: normal normal medium 15px/18px Raleway;
            }

            .tituloMovil{
                display: flex;
                    h1{
                        font: normal normal bold 30px/62px Helvetica;
                        letter-spacing: 0px;
                        color: #050507;
                        opacity: 1;
                        margin: 0;
                    }
                } 

            .topCatalogo{
                display: flex;
                flex-direction: column;

                .textoCatalogo{
                    width:100%;
                    order: 2;
                    gap:20px;
                    justify-content: center;

                    .titulo{
                    display: none;
                    }
                    .rate{
                        .icons{
                            color: #1D4C5A;
                        }
                    }
                    .preciosContent{
                        margin: 0;
                    }
                    .cantidad{
                    }
                    .btnAgregar{}
                }

                .slideCatalogo{
                    order:1;
                    width:100%;  
                            
                    .thumbnail-container {

                        width: auto;
                    }
                    
                    .slider {
                        height: auto;
                        img{
                            height: 340px;
                        }
                    }
                }
            }

            .bottomCatalogo {
                display: inline;
                
                .bottomCatalogo__col{
                    display: grid;
                    gap:20px;
                    margin-bottom: 20px;
                    h1 {
                        font-size: 25px;
                    }
                    h1::after {
                        width: 20%;
                        top: 18px;
                      }
                    .descripcionCatalogo{
                        margin:0px;
                    }
                    ul {
                        display: grid;
                        grid-template-columns:inherit;
                        grid-row-gap: 20px;
                    }
                }
            }
        }
        .crosselling {

            h1 {
            font: normal normal bold 25px Raleway;
            }
        
            h1::after {
            content: "";
            position: absolute;
            width: 20%;
            height: 3px;
            background-color: var(--btn-color);
            top: 15px;
            left: 250px;
            }
        }
    }
    @media only screen and (max-width: 380px) {
        
        .contentCatalogo{
            width: 100%; 
            

            .rutaCatalogo{
              font: normal normal medium 13px/18px Raleway;
            }

            .tituloMovil{
                display: flex;
                h1{
                    font: normal normal bold 20px/62px Helvetica;
                    letter-spacing: 0px;
                    color: #050507;
                    opacity: 1;
                    margin: 0;
                }
          
                } 

            .topCatalogo{
                display: flex;
                flex-direction: column;

                .textoCatalogo{
                    width:100%;
                    order: 2;
                    gap:20px;
                    justify-content: center;

                    .titulo{
                    display: none;
                    }
                    .rate{
                        .icons{
                            color: #1D4C5A;
                        }
                    }
                    .preciosContent{
                        margin: 0;
                    }
                    .cantidad{
                    }
                    .btnAgregar{}
                }

                .slideCatalogo{
                    order:1;
                    width:100%;  
                            
                    .thumbnail-container {

                        width: auto;
                    }
                    
                    .slider {
                        height: 350px;
                        img{
                            height: 340px;
                        }
                    }
                }
            }

            .bottomCatalogo {
                display: inline;
                
                .bottomCatalogo__col{
                    h1 {
                        font-size: 22px;
                    }
                    h1::after {
                        content: "";
                        position: absolute;
                        width: 20%;
                        height: 3px;
                        background-color: var(--btn-color);
                        top: 15px;
                        left: 180px;
                      }
                    ul {
                        display: grid;
                        grid-template-columns:inherit;
                        grid-row-gap: 20px;
                    }
                }
            }
        }

        .crosselling {

            h1 {
            font: normal normal bold 22px Raleway;
            }
        
            h1::after {
            content: "";
            position: absolute;
            width: 20%;
            height: 3px;
            background-color: var(--btn-color);
            top: 10px;
            left: 230px;
            }
        }
        
    }

}