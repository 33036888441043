.wrapperSeccion2 {
    display: flex;
    justify-content: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    padding-top: 60px;
    padding-bottom: 60px;

    .Link:hover {
        text-decoration: none;
    }

    .contenedorSn2 {
        width: 95%;
        align-items: center;
        max-width: 1620px;
        justify-content: center;

        .Ofertastop {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 1s;
            width: 100%;

            .item1 {
                display: flex;
                width: 26%;
                height: 400px;
                margin-right: 15px;
                background: #CDCFD1;

                .imgSec {
                    display: flex;
                    position: relative;
                    width: 100%;

                    .img1 {
                        width: 100%;
                    }
                }

                .content-botones {
                    display: flex;
                    width: 200px;
                    position: absolute;

                    .botones {
                        left: 20px;
                        top: 300px;
                        position: relative;
                        z-index: 10;

                        .btnTitulo1 {
                            font: normal normal normal 18px/20px Raleway;
                            color: #FFFFFF;
                            opacity: 1;
                            margin-bottom: 3px;
                            text-shadow: 1px 1px 0 rgb(128, 128, 128);
                        }

                        .btnTitulo2 {
                            font: normal normal bold 22px/32px Helvetica;
                            color: #FFFFFF;
                            opacity: 1;
                            text-shadow: 1px 1px 0 rgb(128, 128, 128);
                        }

                        .btnTitulo3 {
                            font: normal normal bold 15px/22px Helvetica;
                            color: #FFFFFF;
                            opacity: 1;
                            text-shadow: 1px 1px 0 rgb(128, 128, 128);
                            text-decoration: line-through;
                        }

                        .Link:hover {
                            text-decoration: none;
                        }

                        .btn {

                            border: none;
                            color: white;
                            padding: 4px 15px;
                            text-decoration: none;
                            margin: 4px 2px;
                            cursor: pointer;
                            background-color: var(--btn-color);
                        }

                        .iconCat {
                            font-size: 12px;
                        }
                    }
                }
            }

            .item2 {
                display: flex;
                width: 48%;
                height: 400px;
                background: #CDCFD1;

                .imgPrim {
                    display: flex;

                    width: 100%;

                    .img2 {
                        width: 100%;
                        opacity: 1;
                        transition: opacity 0.5s ease-in-out;
                    }
                }

                .content-botones {
                    display: flex;
                    width: 20%;
                    position: absolute;
                    z-index: 10;

                    .botones {
                        text-align: center;
                        left: 100%;
                        top: 120px;
                        width: 200px;
                        position: absolute;


                        .btnTitulo1 {
                            font: normal normal normal 19px/24px Raleway;
                            color: #FFFFFF;
                            opacity: 1;
                            text-shadow: 1px 1px 0 rgb(128, 128, 128);
                        }

                        .btnTitulo2 {
                            font: normal normal bold 45px/55px Helvetica;
                            color: #FFFFFF;
                            opacity: 1;
                            text-shadow: 1px 1px 0 rgb(128, 128, 128);
                        }

                        .btnTitulo3 {
                            font: normal normal bold 20px/22px Helvetica;
                            color: #FFFFFF;
                            opacity: 1;
                            text-shadow: 1px 1px 0 rgb(128, 128, 128);
                            text-decoration: line-through;
                        }

                        .btnDescripcion {
                            font: normal normal normal 18px/24px Raleway;
                            color: #FFFFFF;
                            opacity: 1;
                        }


                        .Link:hover {
                            text-decoration: none;
                        }

                        .contentBtn {
                            width: 100%;
                            height: 50px;
                            display: flex;
                            background-color: var(--btn-color);
                            justify-content: center;

                            .btn {
                                border: none;
                                color: white;
                                padding: 8px 15px;
                                text-decoration: none;
                                margin: 4px 2px;
                                cursor: pointer;
                                background-color: var(--btn-color);
                            }
                        }

                        .iconCat {
                            font-size: 12px;
                        }
                    }
                }
            }

        }

        .MasOfertas {
            display: flex;
            margin-top: 5px;
            justify-content: space-between;

            .buttons {
                display: flex;
                gap: 5px;
                justify-content: center;


                .contentBtn {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    background-color: var(--btn-color);
                    justify-content: center;
                    align-items: center;

                    .btn {
                        border: none;
                        color: white;
                        padding: 4px 15px;
                        text-decoration: none;
                        margin: 4px 2px;
                        cursor: pointer;
                        background-color: var(--btn-color);
                    }
                }

            }

            .botones {
                text-align: center;
                width: 180px;
                z-index: 10;
                align-items: center;

                .contentBtn {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    background-color: var(--btn-color);
                    justify-content: center;

                    .btn {
                        border: none;
                        color: white;
                        padding: 4px 15px;
                        text-decoration: none;
                        margin: 4px 2px;
                        cursor: pointer;
                        background-color: var(--btn-color);

                        .iconCat {
                            font-size: 12px;
                        }
                    }
                }

            }
        }

    }


    @media only screen and (min-width: 380px) and (max-width: 1000px) {
        .contenedorSn2 {
            transition: 1s;
            width: 100%;

            .top {
                font: var(--Font-top-Min);
            }

            .Ofertastop {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                .item1 {
                    justify-content: center;
                    width: 100%;
                    height: 230px;
                    margin: 0;
                    display: flex;

                    .imgSec {
                        display: flex;
                        position: relative;
                        width: 100%;

                        .img1 {
                            width: 100%;
                        }
                    }

                    .content-botones {
                        width: auto;

                        .botones {
                            top: 100px;
                            left: auto;
                        }
                    }

                }

                .item2 {
                    width: 200%;
                    display: flex;
                    height: 300px;

                    .content-botones {
                        width: 100%;
                        height: 100%;

                        .botones {
                            left: 25%;
                        }
                    }
                }
            }

            .MasOfertas {
                margin-top: 20px;
            }
        }

        .WrapperMid {
            justify-content: center;

            .ContenedorMid {
                width: 100%;

                .mid {
                    .itemMid .logo img {
                        height: 60px;
                        transition: 1s;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 380px) {

        .contenedorSn2 {
            transition: 1s;
            width: 100%;

            .top {
                font: var(--Font-top-Min);
            }

            .Ofertastop {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                .item1 {
                    justify-content: center;
                    width: 100%;
                    height: 230px;
                    margin: 0;
                    display: flex;

                    .imgSec {
                        display: flex;
                        position: relative;
                        width: 100%;

                        .img1 {
                            width: 100%;
                        }
                    }

                    .content-botones {
                        width: auto;

                        .botones {
                            top: 100px;
                            left: auto;
                        }
                    }

                }

                .item2 {
                    width: 200%;
                    display: flex;
                    height: 300px;

                    .content-botones {
                        width: 100%;
                        height: 100%;

                        .botones {
                            left: 25%;
                        }
                    }
                }
            }

            .MasOfertas {
                margin-top: 20px;
            }
        }

        .WrapperMid {
            justify-content: center;

            .ContenedorMid {
                width: 100%;

                .mid {
                    .itemMid .logo img {
                        height: 60px;
                        transition: 1s;
                    }
                }
            }
        }


    }
}